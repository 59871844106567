/**
 * @mixin
 */
const messages = {
  methods: {
    /**
     * Exibe uma mensagem para o usuário usando a biblioteca SweetAlert2.
     *
     * @param {Object} [options] - Opções para customizar a mensagem.
     * @param {string} [options.title=''] - Título da mensagem.
     * @param {string} [options.text=''] - Texto da mensagem.
     * @param {string} [options.icon='info'] - Ícone a ser exibido (pode ser um dos valores: 'success', 'error', 'warning', 'info', 'question').
     * @param {number} [options.timer=3000] - Tempo em milissegundos que a mensagem será exibida antes de ser automaticamente fechada.
     * @param {boolean} [options.showConfirmButton=false] - Define se o botão de confirmação será exibido.
     * @returns {Promise<void>} - a promise that resolves after the message is displayed.
     */
    async showMessage({
      title = '', text = '', icon = 'info', timer = 3000, showConfirmButton = false,
    } = {}) {
      const swalConfig = {
        title,
        text,
        icon,
        showConfirmButton,
        timer,
      }

      await this.$swal(swalConfig)
    },

    /**
     * Shows an error message using SweetAlert2 library with an error icon, a message, and a timer.
     *
     * @param {Error} error - the error object containing the message to display.
     * @return {Promise<void>} - a promise that resolves after the message is displayed.
     */
    async showErrorMessage(error) {
      const swalConfig = {
        icon: 'error',
        title: 'Contate a equipe de desenvolvimento',
        text: error.message,
        showConfirmButton: false,
        timer: 3000,
      }

      await this.$swal(swalConfig)
    },
  },
}

export default messages
