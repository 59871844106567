import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"mr-6 mb-1"},[_vm._v("Digitações")]),_c(VSpacer),_c(VBtn,{staticClass:"mr-4",attrs:{"color":"purple","outlined":""},on:{"click":function($event){return _vm.openModalImportFiles()}}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.icons.mdiProgressUpload))]),_c('span',[_vm._v("Importar")])],1),_c(VBtn,{attrs:{"color":"info","data-test":"button-new","outlined":""},on:{"click":function($event){return _vm.openModal()}}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.icons.mdiPlaylistPlus))]),_c('span',[_vm._v("Novo Protocolo")])],1),_c(VBtn,{staticClass:"ml-4",attrs:{"color":"warning","outlined":""},on:{"click":function($event){return _vm.openHistory()}}},[_c(VIcon,{staticClass:"me-2",attrs:{"size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFilterPlusOutline)+" ")]),_vm._v(" Filtrar ")],1),_c(VBtn,{staticClass:"ml-4",attrs:{"outlined":""},on:{"click":function($event){return _vm.clearFilter()}}},[_c(VIcon,{staticClass:"me-2",attrs:{"size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFilterRemoveOutline)+" ")]),_vm._v(" Limpar filtros ")],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.itemsTable,"loading":_vm.loading,"loading-text":"Carregando dados..."},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('span',[_vm._v("Sem informações para exibir")])]},proxy:true},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.amount)+" ")]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{staticClass:"mr-1",attrs:{"color":"warning","data-test":"button-details"},on:{"click":function($event){return _vm.openDetails(item)}}},[_vm._v(_vm._s(_vm.icons.mdiEye))]),(item.protocol === _vm.loadingReportProtocol ? false : true)?_c(VIcon,{staticClass:"mr-1 ml-1",attrs:{"color":"info"},on:{"click":function($event){return _vm.getProtocolReport(item.protocol)}}},[_vm._v(_vm._s(_vm.icons.mdiFileChart))]):_c(VProgressCircular,{attrs:{"color":"info","size":25,"indeterminate":""}}),_c(VIcon,{staticClass:"mr-1",attrs:{"color":"error","data-test":"button-delete"},on:{"click":function($event){return _vm.deleteRegister(_vm.endpointDelete, item.protocol)}}},[_vm._v(_vm._s(_vm.icons.mdiTrashCanOutline))])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(item.created_at))+" ")]}}],null,true)}),_c(VDialog,{staticClass:"container",attrs:{"width":"700px","persistent":""},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('Modal',{attrs:{"eventList":_vm.eventList,"employeesList":_vm.employeesList},on:{"updatedTable":_vm.updatedTable,"close":function($event){_vm.showModal = false}}})],1),_c(VDialog,{attrs:{"width":"700px","persistent":""},model:{value:(_vm.showDetails),callback:function ($$v) {_vm.showDetails=$$v},expression:"showDetails"}},[(_vm.itemDetails)?[_c('Details',{key:_vm.itemDetails.protocol,attrs:{"data":_vm.itemDetails,"eventList":_vm.eventList,"employeesList":_vm.employeesList},on:{"updatedTable":_vm.updatedTable,"close":function($event){_vm.showDetails = false}}})]:_vm._e()],2),_c(VDialog,{attrs:{"width":"700px"},model:{value:(_vm.showHistory),callback:function ($$v) {_vm.showHistory=$$v},expression:"showHistory"}},[_c('History',{attrs:{"eventList":_vm.eventList,"employeesList":_vm.employeesList,"leaderList":_vm.leaderList},on:{"updatedTable":_vm.updatedTable,"dataFromFilter":_vm.dataFromFilter,"close":function($event){_vm.showHistory = false}}})],1),_c(VDialog,{attrs:{"width":"600px"},model:{value:(_vm.showModalImportFiles),callback:function ($$v) {_vm.showModalImportFiles=$$v},expression:"showModalImportFiles"}},[_c('ImportFiles',{attrs:{"eventList":_vm.eventList},on:{"updatedTable":_vm.updatedTable,"close":function($event){_vm.showModalImportFiles = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }