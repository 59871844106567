<template>
  <v-card>
    <v-card-title>
      <span class="mr-6 mb-1">Digitações</span>

      <v-spacer />

      <v-btn color="purple" class="mr-4" outlined @click="openModalImportFiles()">
        <v-icon class="mr-2">{{ icons.mdiProgressUpload }}</v-icon>
        <span>Importar</span>
      </v-btn>

      <v-btn color="info" data-test="button-new" outlined @click="openModal()">
        <v-icon class="mr-2">{{ icons.mdiPlaylistPlus }}</v-icon>
        <span>Novo Protocolo</span>
      </v-btn>

      <v-btn color="warning" class="ml-4" outlined @click="openHistory()">
        <v-icon size="30" class="me-2">
          {{ icons.mdiFilterPlusOutline }}
        </v-icon>
        Filtrar
      </v-btn>

      <v-btn class="ml-4" outlined @click="clearFilter()">
        <v-icon size="30" class="me-2">
          {{ icons.mdiFilterRemoveOutline }}
        </v-icon>
        Limpar filtros
      </v-btn>
    </v-card-title>

    <v-data-table :headers="headers" :items="itemsTable" :loading="loading" loading-text="Carregando dados...">
      <template v-slot:no-data>
        <span>Sem informações para exibir</span>
      </template>

      <template #[`item.amount`]="{ item }">
        {{ item.amount }}
      </template>

      <template #[`item.options`]="{ item }">
        <v-icon color="warning" data-test="button-details" class="mr-1" @click="openDetails(item)">{{
          icons.mdiEye
        }}</v-icon>

        <v-icon
          v-if="item.protocol === loadingReportProtocol ? false : true"
          color="info"
          class="mr-1 ml-1"
          @click="getProtocolReport(item.protocol)"
          >{{ icons.mdiFileChart }}</v-icon
        >

        <v-progress-circular v-else color="info" :size="25" indeterminate> </v-progress-circular>

        <v-icon
          color="error"
          data-test="button-delete"
          class="mr-1"
          @click="deleteRegister(endpointDelete, item.protocol)"
          >{{ icons.mdiTrashCanOutline }}</v-icon
        >
      </template>

      <template #[`item.created_at`]="{ item }">
        {{ dateFormat(item.created_at) }}
      </template>
    </v-data-table>

    <v-dialog v-model="showModal" class="container" width="700px" persistent>
      <Modal
        :eventList="eventList"
        :employeesList="employeesList"
        @updatedTable="updatedTable"
        @close="showModal = false"
      />
    </v-dialog>

    <v-dialog v-model="showDetails" width="700px" persistent>
      <template v-if="itemDetails">
        <Details
          :key="itemDetails.protocol"
          :data="itemDetails"
          :eventList="eventList"
          :employeesList="employeesList"
          @updatedTable="updatedTable"
          @close="showDetails = false"
        />
      </template>
    </v-dialog>

    <v-dialog v-model="showHistory" width="700px">
      <History
        :eventList="eventList"
        :employeesList="employeesList"
        :leaderList="leaderList"
        @updatedTable="updatedTable"
        @dataFromFilter="dataFromFilter"
        @close="showHistory = false"
      />
    </v-dialog>

    <v-dialog v-model="showModalImportFiles" width="600px">
      <ImportFiles :eventList="eventList" @updatedTable="updatedTable" @close="showModalImportFiles = false" />
    </v-dialog>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import {
  mdiEye,
  mdiFileChart,
  mdiFilterPlusOutline,
  mdiFilterRemoveOutline,
  mdiPlaylistPlus,
  mdiProgressUpload,
  mdiTrashCanOutline,
} from '@mdi/js'
import localStorageSlim from 'localstorage-slim'
import Details from './Details.vue'
import History from './History.vue'
import ImportFiles from './ImportFiles.vue'
import Modal from './Modal.vue'

export default {
  mixins: [formatters, messages],

  components: {
    Modal,
    Details,
    History,
    ImportFiles,
  },

  data() {
    return {
      headers: [
        { text: 'PROTOCOLO', value: 'protocol', align: 'left' },
        { text: 'EVENTO', value: 'event_name', align: 'left' },
        { text: 'DIGITADOR', value: 'user_created_name', align: 'left' },
        { text: 'TOTAL DE REGISTROS', value: 'amount', align: 'center' },
        { text: 'VALOR TOTAL', value: 'value', align: 'center' },
        { text: 'REFERÊNCIA', value: 'reference', align: 'center' },
        { text: 'OPÇÕES', value: 'options', align: 'center' },
      ],

      eventList: [],
      leaderList: [],
      itemsTable: [],
      itemDetails: {},
      employeesList: [],
      listOfFilteredItems: [],

      filterData: '',
      endpointDelete: 'api/v1/integration/typing_sheet/destroy_protocol',

      loading: false,
      showModal: false,
      showDetails: false,
      showHistory: false,
      showModalImportFiles: false,
      loadingReportProtocol: false,

      icons: {
        mdiEye,
        mdiFileChart,
        mdiPlaylistPlus,
        mdiProgressUpload,
        mdiTrashCanOutline,
        mdiFilterPlusOutline,
        mdiFilterRemoveOutline,
      },
    }
  },

  created() {
    this.employeesList = localStorageSlim.get('listEmployees', { decrypt: true })
    this.leaderList = localStorageSlim.get('leaderList', { decrypt: true })
    this.getItemsTable()
    this.getEventList()
  },

  methods: {
    async getItemsTable() {
      this.loading = true

      await axiosIns.get('api/v1/integration/typing_sheet/index').then(response => {
        this.itemsTable = response.data.data
        this.loading = false
      })
    },

    async getEventList() {
      await axiosIns.get('api/v1/integration/event/index').then(response => {
        response.data.data.map(({ id, name, code }) => {
          this.eventList.push({
            id,
            name: `${code} - ${name}`,
            code,
          })
        })

        this.eventList.sort(({ code: firstCode }, { code: secondCode }) => firstCode - secondCode)
      })
    },

    /**
     * @param {string} protocol
     */
    async getProtocolReport(protocol) {
      const body = { protocol_number: protocol }
      this.loadingReportProtocol = protocol

      await axiosIns
        .post('api/v1/integration/typing_sheet/sheet_report', body, { responseType: 'blob' })
        .then(response => {
          const pdfBlob = new Blob([response.data], { type: 'application/pdf' })
          const pdfUrl = URL.createObjectURL(pdfBlob)

          this.loadingReportProtocol = false

          window.open(pdfUrl, '_blank')
        })
        .catch(error => {
          this.showMessage({
            title: 'Erro ao obter relatório',
            text: error,
            icon: 'error',
          })

          this.loadingReportProtocol = false
        })
    },

    updatedTable() {
      this.getItemsTable()
    },

    dataFromFilter(data) {
      this.itemsTable = data
    },

    openModal() {
      this.showModal = true
    },

    openModalImportFiles() {
      this.showModalImportFiles = true
    },

    /**
     *@param {Object.<string, string|number>} itemDetails
     */
    openDetails(itemDetails) {
      this.itemDetails = itemDetails
      this.showDetails = true
    },

    openHistory() {
      this.showHistory = true
    },

    clearFilter() {
      this.filterData = ''
      this.updatedTable()
    },
  },
}
</script>

<style scoped>
.container {
  overflow-y: hidden !important;
}
</style>
