import axiosIns from './axios'

/**
 *
 * @mixin formatters
 */
const formatters = {
  data() {
    return {
      rules: {
        /**
         * Checks that a value exists.
         * @param {string} value - The value to be checked.
         * @returns {string|boolean} - An error string or `true` if the value is valid.
         */
        required: value => !!value || 'Campo obrigatório.',

        /**
         * Checks that a string is not longer than a specified length.
         * @param {string} value - The value to be checked.
         * @param {number} length - The maximum length allowed.
         * @returns {string|boolean} - An error string or `true` if the value is valid.
         */
        maxlength: (value, length = 25) => value.length <= length || `Máximo ${length} caracteres`,

        /**
         * Checks that a string is a valid email address.
         * @param {string} value - The value to be checked.
         * @returns {string|boolean} - An error string or `true` if the value is valid.
         */
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

          return pattern.test(value) || 'Email inválido'
        },

        zeroed: value => (value <= 0 ? 'Não pode ser zero' : null),
      },
    }
  },

  methods: {
    /**
     * Remove as tags HTML de uma string
     * @param {string} str - A string a ser formatada
     * @returns {string} A string sem as tags HTML
     */
    removeHtmlInString(str) {
      if (typeof str !== 'string') {
        return str
      }

      // @ts-ignore
      return str.replaceAll(/<\/?[^>]+>/g, '')
    },

    /**
     * Formats a numeric value as Brazilian currency.
     * @param {number | string} value - The numeric value to format as currency.
     * @returns {string} The formatted string representing the Brazilian currency value.
     */
    formatBrazilianCurrency(value = 0) {
      // @ts-ignore
      if (!value) return value

      const locale = 'pt-BR'
      const options = { style: 'currency', currency: 'BRL' }

      const formattedValue = Number(value).toLocaleString(locale, options)

      return formattedValue
    },

    /**
     * Formata um número de CPF para o padrão XXX.XXX.XXX-XX
     * @param {string} cpf - O número de CPF a ser formatado
     * @returns {string} - O número de CPF formatado ou o valor original caso não seja uma string ou esteja vazio
     */
    formattedCpfNumber(cpf) {
      if (typeof cpf !== 'string' || !cpf) {
        return cpf
      }

      const cpfRegex = /(\d{3})(\d{3})(\d{3})(\d{2})/
      const formattedCpf = cpf.replace(cpfRegex, '$1.$2.$3-$4')

      return formattedCpf
    },

    /**
     * Formata um número de CNPJ no formato 99.999.999/9999-99.
     * @param {string} number - O número do CNPJ a ser formatado.
     * @returns {string|undefined} O número do CNPJ formatado ou o parâmetro number se o argumento não for uma string.
     */
    formattedCnpjNumber(number) {
      if (typeof number !== 'string') {
        return number
      }

      const cnpjPattern = /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/
      const cnpjFormatted = number.replace(cnpjPattern, '$1.$2.$3/$4-$5')

      return cnpjFormatted
    },

    /**
    Retorna o número de telefone formatado no padrão brasileiro (XX) X XXXX-XXXX.
    *
    * @param {string} phoneNumber - O número de telefone a ser formatado.
    * @returns {string} O número de telefone formatado ou o valor passado caso não seja uma string válida.
    */
    formattedPhoneNumberToBrazil(phoneNumber) {
      if (typeof phoneNumber !== 'string' || !phoneNumber) {
        return phoneNumber
      }

      const phoneNumberRegex = /(\d{2})(\d{1})(\d{4})(\d{4})/
      const formattedPhoneNumber = phoneNumber.replace(phoneNumberRegex, '($1) $2 $3-$4')

      return formattedPhoneNumber
    },

    /**
     * Formats a given date string into a human-readable format. If the second parameter is set to true, it returns the date part only.
     *
     * @param {string} date - The date string to be formatted.
     * @param {boolean} [isDateWithoutTime=false] - Optional flag to indicate if the formatted date should not include the time part.
     * @return {string} - The formatted date string.
     */
    dateFormat(date, isDateWithoutTime = false) {
      if (typeof date !== 'string' || !date) {
        return date
      }

      const dateSliced = date.slice(0, 10)

      const hour = date.slice(11, 19)

      const dateDivided = dateSliced.split('-')

      const dateFormated = `${dateDivided[2]}/${dateDivided[1]}/${dateDivided[0]} - ${hour}`

      if (!hour || isDateWithoutTime) {
        return `${dateDivided[2]}/${dateDivided[1]}/${dateDivided[0]}`
      }

      return dateFormated
    },

    /**
     * Deleta registros a partir de um endpoint e id do registro
     *
     * @param {string} endpoint -
     * @param {string} id - Id do registro a ser apagado
     * @returns {Promise<void>}
     */
    async deleteRegister(endpoint, id, title, text) {
      const confirmed = await this.confirmDeletion(title, text)
      if (!confirmed) {
        this.showCancelledMessage()

        return
      }

      try {
        this.showLoadingMessage()
        await this.deleteRecord(endpoint, id)
        this.$swal.close()

        this.showSuccessMessage()
        this.updatedTable()
      } catch (error) {
        this.showErrorMessage()
      } finally {
        this.closeModal()
      }
    },

    async confirmDeletion(title, text) {
      const result = await this.$swal({
        icon: 'warning',
        title: title || 'Deseja excluir o registro?',
        text: text || 'A operação é irreversível, tem certeza?',
        showCancelButton: true,
        confirmButtonText: 'Sim!',
        cancelButtonText: 'Não',
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#dc3545',
      })

      return result.isConfirmed
    },

    async deleteRecord(endpoint, id) {
      await axiosIns.delete(`${endpoint}/${id}`)
    },

    /**
 * Faz uma requisição POST para criar um novo registro.
 *
 * @param {string} endpoint - O endpoint da API para enviar o POST.
 * @param {Object} body - O corpo da requisição a ser enviado no POST.
 * @param {string} [title] - O título da mensagem de confirmação.
 * @param {string} [text] - O texto da mensagem de confirmação.
 * @returns {Promise<void>}
 */
    async postRegister(endpoint, body, title, text) {
      const confirmed = await this.confirmPost(title, text)
      if (!confirmed) {
        this.showCancelledMessage()

        return
      }
      try {
        this.showLoadingMessageCreate()
        await this.createRecord(endpoint, body)
        this.showCreateMessage('Registro criado com sucesso!')
      } catch (error) {
        this.showErrorCreateMessage()
      }
    },

    /**
 * Confirmação para realizar a operação de POST.
 *
 * @param {string} [title] - O título da mensagem de confirmação.
 * @param {string} [text] - O texto da mensagem de confirmação.
 * @returns {Promise<boolean>} - Retorna `true` se o usuário confirmar, caso contrário `false`.
 */
    async confirmPost(title, text) {
      const result = await this.$swal({
        icon: 'warning',
        title: title || 'Deseja criar o novo registro?',
        text: text || 'Tem certeza que deseja continuar?',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#dc3545',
      })

      return result.isConfirmed
    },

    /**
 * Realiza a requisição POST para o endpoint fornecido com o corpo fornecido.
 *
 * @param {string} endpoint - O endpoint da API.
 * @param {Object} body - O corpo da requisição a ser enviado.
 * @returns {Promise<void>}
 */
    async createRecord(endpoint, body) {
      await axiosIns.post(endpoint, body)
    },

    showSuccessMessage() {
      this.$swal({
        icon: 'success',
        title: 'Registro excluído!',
        text: 'Feito com sucesso!',
        showConfirmButton: false,
        timer: 2000,
      })
    },
    showErrorCreateMessage() {
      this.$swal({
        icon: 'error',
        title: 'Ocorreu um erro!',
        text: 'Ocorreu um erro ao efetuar registro!',
        showConfirmButton: true,
        timer: 5000,
      })
    },
    showCreateMessage() {
      this.$swal({
        icon: 'success',
        title: 'Registro efetuado!',
        text: 'Feito com sucesso!',
        showConfirmButton: false,
        timer: 2000,
      })
    },
    showLoadingMessageCreate() {
      this.$swal({
        icon: 'info',
        title: 'Efetuando registro...',
        timerProgressBar: true,
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading()
        },
      })
    },
    showCancelledMessage() {
      this.$swal({
        icon: 'error',
        title: 'Cancelado!',
        text: 'Operação cancelada',
        showConfirmButton: false,
        timer: 2000,
      })
    },

    showErrorMessage() {
      this.$swal({
        icon: 'error',
        title: 'Erro ao excluir registro!',
        text: 'Você não pode apagar este registro, Tente editar!',
      })
    },

    showLoadingMessage() {
      this.$swal({
        icon: 'info',
        title: 'Excluindo registro...',
        timerProgressBar: true,
        showConfirmButton: false,
        allowOutsideClick: false,

        didOpen: () => {
          this.$swal.showLoading()
        },
      })
    },

    updatedTable() {
      this.$emit('updatedTable')
    },

    closeModal() {
      this.$emit('close')
    },
  },
}

export default formatters
