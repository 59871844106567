<template>
  <v-card>
    <v-card-title class="d-flex justify-space-between mb-2">
      <span>Cadastrar folha</span>

      <v-btn color="info" fab small outlined>
        <v-icon data-test="button-add-register" @click="addRegister()" dark>{{ icons.mdiPlus }} </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-dialog
        ref="dialogReferenceDate"
        v-model="showCalendarReferenceDate"
        :return-value.sync="referenceDate"
        width="400px"
        persistent
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="computedReferenceDate"
            :append-icon="icons.mdiCalendar"
            :disabled="disableReferenceDate"
            label="Mês Referência"
            readonly
            outlined
            dense
            v-bind="attrs"
            v-on="on"
          />
        </template>

        <v-date-picker
          v-model="referenceDate"
          :allowed-dates="allowedMonths"
          width="400px"
          locale="pt-BR"
          type="month"
          scrollable
          persistent
          @dblclick:month="handleMonthSelection()"
        >
          <v-spacer />
          <v-btn text color="primary" @click="showCalendarReferenceDate = false"> Cancelar </v-btn>
          <v-btn text color="primary" @click="$refs.dialogReferenceDate.save(referenceDate)"> OK </v-btn>
        </v-date-picker>
      </v-dialog>

      <v-autocomplete
        v-model="eventData"
        :items="eventList"
        item-text="name"
        :item-value="item => getEventData(item)"
        :rules="[rules.required]"
        label="Selecione o evento"
        :disabled="disableEventData"
        outlined
        dense
      />

      <v-form ref="form">
        <v-autocomplete
          ref="inputEmployee"
          v-model="employeeData"
          :items="employeesList"
          item-text="name"
          :item-value="item => getEmployeeData(item)"
          :rules="[rules.required]"
          label="Selecione o colaborador"
          outlined
          dense
        />

        <v-text-field
          v-model="inputValue"
          :rules="[rules.required]"
          v-on:keyup.enter="addRegister()"
          label="Valor"
          type="number"
          outlined
          dense
        />

        <v-list subheader rounded outlined class="list-container">
          <v-subheader>Registros</v-subheader>

          <v-list-item v-for="register in registers" :key="register.id">
            <v-list-item-avatar>
              <v-icon color="info"> {{ icons.mdiTextBoxOutline }}</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="mb-2">Nome: {{ register.name }}</v-list-item-title>

              <v-list-item-subtitle>
                Valor: {{ Number(register.value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) }} -
                Evento: {{ register.event }}</v-list-item-subtitle
              >
            </v-list-item-content>

            <v-list-item-action>
              <v-btn icon>
                <v-icon color="error" @click="deleteRegisterItem(register.id)">{{ icons.mdiTrashCan }}</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-form>

      <div class="d-flex justify-end mt-5">
        <v-btn color="error" class="mr-5" @click="closeModal(), resetForm(), resetReferenceDate()">
          <span>Fechar</span>
        </v-btn>

        <v-btn color="info" @click="sendModalData()">
          <span v-if="!isLoading">Enviar</span>
          <v-progress-circular v-else color="white" indeterminate> </v-progress-circular>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import { mdiPlus, mdiTextBoxOutline, mdiTrashCan } from '@mdi/js'

export default {
  mixins: [messages, formatters],

  props: {
    employeesList: {
      type: Array,
      required: true,
    },

    eventList: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      inputValue: '',
      referenceDate: '',

      registers: [],
      /** @type {EventData | undefined} */
      eventData: undefined,
      /** @type {EventData | undefined} */
      employeeData: undefined,

      isLoading: false,
      disableEventData: false,
      disableReferenceDate: false,
      showCalendarReferenceDate: false,

      icons: {
        mdiPlus,
        mdiTrashCan,
        mdiTextBoxOutline,
      },
    }
  },

  computed: {
    computedReferenceDate: {
      get() {
        return this.formatDate(this.referenceDate) ?? ''
      },

      set() {},
    },

    allowedMonths() {
      const today = new Date()
      const currentDay = today.getDate()
      const currentMonth = today.getMonth() + 1

      if (currentDay >= 7) {
        return date => {
          const [selectedYear, selectedMonth] = date.split('-').map(Number)

          if (
            selectedYear < today.getFullYear() ||
            (selectedYear === today.getFullYear() && selectedMonth < currentMonth)
          ) {
            return false
          }

          return true
        }
      }

      return () => true
    },
  },

  watch: {
    referenceDate() {
      if (this.referenceDate !== '') {
        this.disableReferenceDate = true
      }
    },

    eventData() {
      this.disableEventData = true
    },
  },

  methods: {
    async sendModalData() {
      const body = this.registers

      if (this.referenceDate === '') {
        this.showMessage({
          title: 'Data de referencia vazia!',
          text: 'Você deve incluir o mês de referencia',
        })

        return
      }

      if (this.registers.length > 0) {
        this.isLoading = true

        await axiosIns
          .post('api/v1/integration/typing_sheet/store', body)
          .then(() => {
            this.showMessage({
              icon: 'success',
              title: 'Sucesso!',
              text: 'Sua resposta enviada com sucesso!',
            })

            this.resetForm()
            this.updatedTable()
            this.registers = []
          })
          .catch(error => {
            this.showMessage({
              icon: 'error',
              title: 'Erro ao enviar dados!',
              text: error,
            })
          })
          .finally(() => {
            this.isLoading = false
            this.closeModal()
            this.resetReferenceDate()
          })

        return
      }

      this.showMessage({
        title: 'Sem registros!',
        text: 'Você deve incluir ao menos um registro',
      })
    },

    addRegister() {
      const formIsValid = this.$refs.form.validate()
      const registers = this.registers
      const lastItem = registers[this.registers.length - 1]

      if (formIsValid) {
        if (lastItem !== undefined && lastItem?.events_id !== this.eventData.id) {
          this.showMessage({
            title: 'Evento Errado',
            text: `Você só pode adicionar registros com evento: ${lastItem.event}`,
          })

          return
        }

        if (this.referenceDate === '') {
          this.showMessage({
            title: 'Data de referencia vazia!',
            text: 'Você deve incluir o mês de referencia',
          })

          return
        }

        this.registers.unshift({
          id: Math.random(),
          name: this.employeeData?.name,
          event: this.eventData?.name,
          value: this.inputValue,
          events_id: this.eventData?.id,
          users_id: this.employeeData?.id,
          reference: this.referenceDate,
        })

        this.resetForm()
        this.focusInputEmployee()

        return
      }

      this.showMessage({
        title: 'Campos vazios!',
        text: 'Verifique se todos os campos estão preenchidos',
      })
    },

    /**
     * @typedef {Object} EventData
     * @property {string} id
     * @property {string} name
     */

    /**
     * @param {EventData} item
     */
    getEmployeeData(item) {
      return { id: item?.id, name: item?.name }
    },

    /**
     * @param {EventData} item
     */
    getEventData(item) {
      return { id: item?.id, name: item?.name }
    },

    handleMonthSelection() {
      this.$refs.dialogReferenceDate.save(this.referenceDate)
      this.showCalendarReferenceDate = false
    },

    resetForm() {
      const { form } = this.$refs
      form.reset()
    },

    focusInputEmployee() {
      const { inputEmployee } = this.$refs
      inputEmployee.focus()
    },

    /**
     * @param {string} id
     */
    deleteRegisterItem(id) {
      this.registers = this.registers.filter(({ id: registerId }) => registerId !== id)
    },

    /**
     * Brazilian standard date format
     *
     * @param {string} date
     * @returns {string}
     */
    formatDate(date) {
      if (date) {
        const [year, month] = date.split('-')

        return `${month}-${year}`
      }

      return
    },

    async resetReferenceDate() {
      this.eventData = { id: '', name: '' }
      await this.$nextTick()

      this.referenceDate = ''
      this.disableEventData = false
      this.disableReferenceDate = false
    },
  },
}
</script>

<style scoped>
/* Retira o incrementador padrão dos inputs do tipo "number"*/
/*Remove incrementer from numeric type inputs */
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}
.list-container {
  overflow-y: scroll;
  height: auto;
  max-height: 280px;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: #d9d9d9;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
