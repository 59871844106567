<template>
  <v-card>
    <v-card-title class="mb-4 justify-center"> Importar planilha</v-card-title>

    <v-card-text>
      <v-form ref="form">
        <v-file-input
          v-model="fileList"
          label="Inserir arquivo"
          accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv"
          :append-icon="icons.mdiPaperclip"
          :rules="[rules.required]"
          prepend-icon=""
          outlined
          dense
        />

        <v-autocomplete
          v-model="eventIdSelected"
          :items="eventList"
          :rules="[rules.required]"
          item-text="name"
          item-value="id"
          label="Selecione o evento"
          outlined
          dense
        />

        <v-dialog
          ref="dialogReferenceDate"
          v-model="showCalendarReferenceDate"
          :return-value.sync="referenceDate"
          width="400px"
          persistent
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedReferenceDate"
              :append-icon="icons.mdiCalendar"
              :rules="[rules.required]"
              label="Mês Referência"
              readonly
              outlined
              dense
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>

          <v-date-picker
            v-model="referenceDate"
            :allowed-dates="allowedMonths"
            @dblclick:month="handleMonthSelection()"
            width="400px"
            locale="pt-BR"
            type="month"
            scrollable
          >
            <v-spacer />
            <v-btn text color="primary" @click="showCalendarReferenceDate = false"> Cancelar </v-btn>
            <v-btn text color="primary" @click="$refs.dialogReferenceDate.save(referenceDate)"> OK </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-form>

      <div class="d-flex justify-end mt-2">
        <v-btn color="error" class="mr-5" data-test="button-close" @click="closeModal(), resetForm()">
          <span> Fechar </span>
        </v-btn>

        <v-btn color="info" data-test="button-send" @click="sendData()">
          <span v-if="!isLoading">Enviar</span>
          <v-progress-circular v-else color="white" indeterminate> </v-progress-circular>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import { mdiCalendar, mdiPaperclip } from '@mdi/js'

export default {
  mixins: [messages, formatters],

  props: {
    eventList: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      fileList: [],
      referenceDate: '',
      eventIdSelected: '',

      isLoading: false,
      showCalendarReferenceDate: false,

      icons: {
        mdiCalendar,
        mdiPaperclip,
      },
    }
  },

  computed: {
    computedReferenceDate: {
      get() {
        return this.formatDate(this.referenceDate)
      },

      set() {},
    },

    allowedMonths() {
      const today = new Date()
      const currentDay = today.getDate()
      const currentMonth = today.getMonth() + 1

      if (currentDay >= 7) {
        return date => {
          const [selectedYear, selectedMonth] = date.split('-').map(Number)

          if (
            selectedYear < today.getFullYear() ||
            (selectedYear === today.getFullYear() && selectedMonth < currentMonth)
          ) {
            return false
          }

          return true
        }
      }

      return () => true
    },
  },

  methods: {
    async sendData() {
      const formIsValid = this.$refs.form.validate()

      if (formIsValid) {
        this.isLoading = true

        const formData = new FormData()
        const { fileList, eventIdSelected, referenceDate } = this

        formData.append('files[]', fileList)
        formData.append('events_id', eventIdSelected)
        formData.append('reference', referenceDate)

        await axiosIns
          .post('api/v1/integration/typing_sheet/import', formData)
          .then(() => {
            this.showMessage({
              icon: 'success',
              title: 'Sucesso!',
              text: 'Seu arquivo foi enviado com sucesso!',
            })

            this.resetForm()
            this.updatedTable()
          })
          .catch(error => {
            this.showMessage({
              icon: 'error',
              title: 'Erro ao enviar dados!',
              text: error,
            })
          })
          .finally(() => {
            this.isLoading = false
            this.closeModal()
          })

        return
      }

      this.showMessage({
        title: 'Campos vazios!',
        text: 'Verifique se todos os campos estão preenchidos',
      })
    },

    handleMonthSelection() {
      this.$refs.dialogReferenceDate.save(this.referenceDate)
      this.showCalendarReferenceDate = false
    },

    /**
     * Brazilian standard date format
     *
     * @param {string} date
     * @returns {string}
     */
    formatDate(date) {
      if (date) {
        const [year, month] = date.split('-')

        return `${month}-${year}`
      }

      return
    },

    resetForm() {
      const { form } = this.$refs
      form.reset()
    },
  },
}
</script>
