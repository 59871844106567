<template>
  <v-skeleton-loader v-if="skeletonLoader" type="article, actions"></v-skeleton-loader>
  <v-card :key="data.protocol" v-else>
    <v-card-title class="d-flex justify-space-between mb-2">
      <span>Editar folha</span>

      <v-btn color="info" fab small outlined>
        <v-icon data-test="button-add-register" @click="addRegister()" dark>{{ icons.mdiPlus }} </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-text-field v-model="computedReferenceDate" label="Mês de referencia" outlined readonly dense />

      <v-text-field v-model="computedEvent" outlined readonly dense />

      <v-form ref="form">
        <v-autocomplete
          ref="inputEmployee"
          v-model="employeeData"
          :items="employeesList"
          item-text="name"
          :item-value="item => getEmployeeData(item)"
          :rules="[rules.required]"
          label="Selecione o colaborador"
          outlined
          dense
        />

        <v-text-field
          v-model="inputValue"
          :rules="[rules.required]"
          v-on:keyup.enter="addRegister()"
          label="Valor"
          type="number"
          outlined
          dense
        />

        <v-list subheader rounded outlined class="list-container">
          <v-subheader>Registros</v-subheader>

          <v-list-item v-for="register in registers" :key="register.id">
            <v-list-item-avatar>
              <v-icon color="info"> {{ icons.mdiTextBoxOutline }}</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="mb-2">Nome: {{ register.user.name }}</v-list-item-title>

              <v-list-item-subtitle>
                Valor: {{ Number(register.value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) }} -
                Evento: {{ register.event.code }} - {{ register.event.name }}</v-list-item-subtitle
              >
            </v-list-item-content>

            <v-list-item-action>
              <v-btn icon>
                <v-icon
                  v-if="register.id === loadingDelete ? false : true"
                  color="error"
                  class="mt-2"
                  @click="deleteRegisterItem(register.id)"
                  >{{ icons.mdiTrashCan }}</v-icon
                >
                <v-progress-circular v-else color="error" indeterminate> </v-progress-circular>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-form>

      <div class="d-flex justify-end mt-5">
        <v-btn color="error" class="mr-5" @click="closeModal(), resetForm()"> Fechar </v-btn>

        <v-btn color="info" @click="sendModalData()">
          <span v-if="!loadingCircle">Enviar</span>
          <v-progress-circular v-else color="white" indeterminate> </v-progress-circular>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import { mdiPlus, mdiTextBoxOutline, mdiTrashCan } from '@mdi/js'

export default {
  mixins: [messages, formatters],

  props: {
    data: {
      type: Object,
      required: true,
    },

    employeesList: {
      type: Array,
      required: true,
    },

    eventList: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      inputValue: '',

      /** @type {EventData | undefined} */
      employeeData: undefined,
      /** @type {EventData | undefined} */
      eventData: undefined,

      registers: [],
      registersFromBackEnd: [],

      expand: false,
      loadingCircle: false,
      loadingDelete: false,
      skeletonLoader: false,

      icons: {
        mdiPlus,
        mdiTrashCan,
        mdiTextBoxOutline,
      },
    }
  },

  computed: {
    computedReferenceDate() {
      return this.registers[0]?.reference
    },

    computedEvent() {
      return this.eventData?.name
    },
  },

  created() {
    this.getDataById()
  },

  methods: {
    async getDataById() {
      this.skeletonLoader = true
      const { protocol } = this.data

      await axiosIns.get(`api/v1/integration/typing_sheet/index_protocol/${protocol}`).then(response => {
        this.registers = response.data.data

        const { id: eventId, name: eventName, code: eventCode } = this.registers[0].event
        this.eventData = {
          id: eventId,
          name: `${eventCode} - ${eventName}`,
        }

        this.skeletonLoader = false
      })
    },

    async sendModalData() {
      const body = this.registersFromBackEnd

      if (this.registersFromBackEnd.length > 0) {
        this.loadingCircle = true
        this.resetForm()

        await axiosIns
          .post('api/v1/integration/typing_sheet/store', body)
          .then(() => {
            this.showMessage({
              icon: 'success',
              title: 'Sucesso!',
              text: 'Sua resposta enviada com sucesso!',
            })

            this.updatedTable()
            this.loadingCircle = false
          })
          .catch(error => {
            this.showMessage({
              icon: 'error',
              title: 'Erro ao enviar dados!',
              text: error,
            })
          })

        this.registersFromBackEnd = []
        this.closeModal()
        this.getDataById()

        return
      }

      this.showMessage({
        title: 'Sem registros!',
        text: 'Você deve incluir ao menos um registro',
      })
    },

    addRegister() {
      const formIsValid = this.$refs.form.validate()
      const registers = this.registers
      const lastItem = registers[this.registers.length - 1]

      if (formIsValid) {
        if (lastItem !== undefined && lastItem?.events_id !== this.eventData.id) {
          this.showMessage({
            title: 'Evento Errado',
            text: `Você só pode adicionar registros com evento: ${lastItem.event?.name}`,
          })

          return
        }

        this.registers.unshift({
          id: Math.random(),
          user: { name: this.employeeData?.name },
          event: { name: this.eventData?.name },
          value: this.inputValue,
          events_id: this.eventData?.id,
          users_id: this.employeeData?.id,
          protocol: this.data.protocol,
          createdOnFrontEnd: true,
        })

        this.registersFromBackEnd.unshift({
          id: Math.random(),
          user: { name: this.employeeData?.name },
          event: { name: this.eventData?.name },
          value: this.inputValue,
          events_id: this.eventData?.id,
          users_id: this.employeeData?.id,
          protocol: this.data.protocol,
          reference: this.convertDateToISOFormat(lastItem.reference),
        })

        this.resetForm()
        this.focusInputEmployee()

        return
      }

      this.showMessage({
        title: 'Campos vazios!',
        text: 'Verifique se todos os campos estão preenchidos',
      })
    },

    /**
     * @param {string | number} id
     */
    async deleteRegisterItem(id) {
      this.loadingDelete = id
      const objectCreatedOnFrontEnd = this.registers.find(({ id: registerId }) => registerId === id)

      if (objectCreatedOnFrontEnd.createdOnFrontEnd) {
        this.registers = this.registers.filter(({ id }) => id !== objectCreatedOnFrontEnd.id)
        this.loadingDelete = false

        this.resetForm()

        return
      }

      await axiosIns
        .delete(`api/v1/integration/typing_sheet/destroy/${id}`)
        .then(() => {
          this.showMessage({
            icon: 'success',
            title: 'Sucesso',
            text: 'Registro excluído com sucesso!',
          })

          this.registers = this.registers.filter(({ id: registerId }) => registerId !== id)

          if (this.registers.length === 0) {
            this.updatedTable()
            this.closeModal()
          }
        })
        .catch(error => {
          this.showMessage({
            icon: 'error',
            title: 'Erro ao excluir!',
            text: error,
          })
        })

      this.loadingDelete = false
    },

    /**
     * @typedef {Object} EventData
     * @property {string} id
     * @property {string} name
     */

    /**
     * @param {EventData} item
     */
    getEmployeeData(item) {
      return { id: item?.id, name: item?.name }
    },

    /**
     * @param {EventData} item
     */
    getEventData(item) {
      return { id: item?.id, name: item?.name }
    },

    /**
     * @param {string} dateStr - A string representing a date in the format 'JAN/2023'
     * @returns {string} A string representing the date in the format 'yyyy-mm'.
     */
    convertDateToISOFormat(dateStr) {
      if (!dateStr) return ''

      const [month, year] = dateStr.split('/')

      const monthMap = {
        JAN: 0,
        FEV: 1,
        MAR: 2,
        ABR: 3,
        MAI: 4,
        JUN: 5,
        JUL: 6,
        AGO: 7,
        SET: 8,
        OUT: 9,
        NOV: 10,
        DEZ: 11,
      }

      const monthNum = monthMap[month.toUpperCase()]
      const date = new Date(year, monthNum, 1)
      const yearStr = date.getFullYear().toString()
      const monthStr = (date.getMonth() + 1).toString().padStart(2, '0')

      const isoDate = `${yearStr}-${monthStr}`

      return isoDate
    },

    resetForm() {
      this.$refs.form.reset()
    },

    focusInputEmployee() {
      this.$refs.inputEmployee.focus()
    },
  },
}
</script>

<style lang="scss">
/* Retira o incrementador padrão dos inputs do tipo "number"*/
/*Remove incrementer from numeric type inputs */
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.list-container {
  overflow-y: scroll;
  height: auto;
  max-height: 280px;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: #d9d9d9;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
