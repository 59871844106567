<template>
  <v-card>
    <v-card-title class="justify-center">
      Filtrar dados
    </v-card-title>

    <v-card-text>
      <v-form ref="form">
        <v-dialog
          ref="dialogReferenceDate"
          v-model="showReferenceDateCalendar"
          :return-value.sync="referenceDate"
          width="400px"
          persistent
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedReferenceDate"
              label="Mês Referência"
              readonly
              outlined
              dense
              v-bind="attrs"
              v-on="on"
            >
              <template v-slot:append>
                <v-icon>{{ icons.mdiCalendar }}</v-icon>
              </template>
            </v-text-field>
          </template>

          <v-date-picker
            v-model="referenceDate"
            width="400px"
            locale="pt-BR"
            type="month"
            scrollable
            @dblclick:month="handleMonthSelection()"
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="showReferenceDateCalendar = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.dialogReferenceDate.save(referenceDate)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>

        <v-text-field
          v-model="protocol"
          label="Número de protocolo"
          type="number"
          outlined
          dense
        />

        <v-autocomplete
          v-model="typistUser"
          :items="leaderList"
          item-text="name"
          item-value="id"
          label="Digitador"
          outlined
          dense
        />

        <v-autocomplete
          v-model="employeeIdSelected"
          :items="employeesList"
          item-text="name"
          item-value="id"
          label="Colaborador"
          outlined
          dense
        />

        <v-autocomplete
          v-model="eventIdSelected"
          :items="eventList"
          item-text="name"
          item-value="id"
          label="Evento"
          outlined
          dense
        />
      </v-form>

      <div class="d-flex justify-end mt-5">
        <v-btn
          color="error"
          @click="closeModal(), resetForm()"
        >
          Fechar
        </v-btn>

        <v-btn
          color="info"
          class="ml-5"
          @click="getFilterData()"
        >
          <span v-if="!isLoading">enviar</span>

          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import messages from '@/plugins/showMessageMixin'
import { mdiCalendar } from '@mdi/js'

export default {
  mixins: [messages],

  props: {
    employeesList: {
      type: Array,
      required: true,
    },

    leaderList: {
      type: Array,
      required: true,
    },

    eventList: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      protocol: '',
      typistUser: '',
      referenceDate: '',
      eventIdSelected: '',
      employeeIdSelected: '',

      isLoading: false,
      showReferenceDateCalendar: false,

      icons: {
        mdiCalendar,
      },
    }
  },

  computed: {
    computedReferenceDate: {
      get() {
        return this.referenceDate ? this.formatDate(this.referenceDate) : ''
      },

      set() {},
    },
  },

  methods: {
    async getFilterData() {
      const {
        referenceDate, typistUser, employeeIdSelected, eventIdSelected, protocol,
      } = this

      const body = {
        protocol,
        reference: referenceDate,
        users_created_id: typistUser,
        users_id: employeeIdSelected,
        events_id: eventIdSelected,
      }

      if (this.hasNonEmptyKey(body)) {
        this.isLoading = true

        await axiosIns
          .post('api/v1/integration/typing_sheet/filter', body)
          .then(response => {
            const dataFiltered = response.data.data

            if (dataFiltered.length === 0) {
              this.showMessage({ title: 'Nenhum registro encontrado!', text: 'Tente novamente' })

              this.isLoading = false

              return
            }

            this.showMessage({ title: 'Sucesso', text: 'Dados obtidos com sucesso', icon: 'success' })
            this.sendFilterData(dataFiltered)
          })
          .catch(error => {
            this.showMessage({
              title: 'Erro ao enviar dados',
              text: error,
              icon: 'error',
            })
          }).finally(() => {
            this.isLoading = false
            this.resetForm()
            this.closeModal()
          })

        return
      }

      this.showMessage({
        title: 'Filtros vazios',
        text: 'Você deve preencher ao menos um dos campos',
      })
    },

    handleMonthSelection() {
      this.$refs.dialogReferenceDate.save(this.referenceDate)
      this.showReferenceDateCalendar = false
    },

    sendFilterData(data) {
      if (typeof data === 'object') {
        this.$emit('dataFromFilter', data)
      }
    },

    /**
     * Checks if any key in the given object has a non-empty value.
     * @param {object} obj - The object to check.
     * @returns {boolean} - True if any key has a non-empty value, false otherwise.
     */
    hasNonEmptyKey(obj) {
      return Object.keys(obj).some(key => {
        const value = obj[key]

        return value !== '' && value
      })
    },

    /**
     * Brazilian standard date format
     *
     * @param {string} date
     * @returns {string}
     */
    formatDate(date) {
      if (typeof date === 'string') {
        const [year, month] = date.split('-')

        return `${month}/${year}`
      }

      return date
    },

    closeModal() {
      this.$emit('close')
    },

    resetForm() {
      this.referenceDate = ''
      this.$refs.form.reset()
    },
  },
}
</script>
